'use client';
import * as React from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/src/lib/utils';
import { GtmEvent, trackGtmEvent } from '@/src/helpers/analytics.ts';
import { ComponentType, PropsWithoutRef, ReactElement } from 'react';
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '@/src/components/ui/tooltip';

const buttonVariants = cva(
  'button inline-flex items-center justify-center rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary/90 ring-primary ring-1 text-primary-foreground hover:bg-accent hover:text-accent-foreground',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background text-foreground hover:bg-foreground/5 hover:text-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        fancy:
          '[&:not(:hover)]:bg-gradient-to-r from-secondary/80 via-secondary/70 to-secondary animate-bg text-secondary-foreground hover:bg-neutral-400 hover:text-accent-foreground',
        colorful:
          '[&:not(:hover)]:bg-gradient-to-r from-pink-300 to-violet-300 text-white hover:bg-accent hover:text-accent-foreground',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'rounded-md text-lg px-5 py-4',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  arrow?: boolean;
  clickTrackGtmEvent?: GtmEvent;
  disabledTooltip?: string;
}

const withButtonDisabledTooltip = <P extends ButtonProps>(
  Component: ComponentType<P>,
) => {
  // ForwardRef zur Unterstützung des ref
  // eslint-disable-next-line react/display-name
  return React.forwardRef<unknown, P>(({ disabledTooltip, ...props }, ref) => {
    const component = <Component ref={ref} {...(props as P)} />;

    if (props.disabled && disabledTooltip) {
      return (
        <TooltipProvider delayDuration={50}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div>{component}</div>
            </TooltipTrigger>
            <TooltipContent>{disabledTooltip}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    return component;
  });
};

const InternButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      children,
      arrow = false,
      asChild = false,
      clickTrackGtmEvent,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    const onClick = () => {
      if (clickTrackGtmEvent) {
        trackGtmEvent(clickTrackGtmEvent);
      }
    };

    return (
      <Comp
        className={cn('group', buttonVariants({ variant, size, className }))}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        <Slottable>{children}</Slottable>
        {arrow && (
          <span className="ml-2 !transition-transform duration-500 group-hover:translate-x-1">
            &raquo;
          </span>
        )}
      </Comp>
    );
  },
);
InternButton.displayName = 'Button';

const Button = withButtonDisabledTooltip(InternButton);

export { Button, buttonVariants };
