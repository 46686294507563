'use client';

import {
  createLocalizedPathnamesNavigation,
  Pathnames,
} from 'next-intl/navigation';
import React, { PropsWithChildren } from 'react';
import { AvailableLanguages } from '@/src/lib/constant.ts';
import {
  AbstractIntlMessages,
  NextIntlClientProvider,
  useTranslations,
} from 'next-intl';

const LanguageContext = React.createContext<
  { pathnames: Pathnames<any> } & ReturnType<
    typeof createLocalizedPathnamesNavigation
  >
>(null as any); // zu wild

export const useLanguageContext = () => React.useContext(LanguageContext);

type LanguageRouter = ReturnType<
  typeof createLocalizedPathnamesNavigation
>['useRouter'];
export type TFunction =
  | ReturnType<typeof useTranslations>
  | ReturnType<typeof useTranslations>['rich'];

export type TResult = ReturnType<TFunction>;

const useProxyRouter = (useSourceRouter: LanguageRouter) => {
  return React.useCallback(() => {
    const router = useSourceRouter();
    return {
      ...router,
      push(...args: Parameters<ReturnType<LanguageRouter>['push']>) {
        window.dispatchEvent(new Event('pageTransitionStart'));
        router.push(...args);
      },
    };
  }, [useSourceRouter]);
};

export default function ProvideLanguageContext({
  children,
  pathnames,
  locale,
  messages,
  timeZone,
}: PropsWithChildren<{
  pathnames: Pathnames<any>;
  locale: (typeof AvailableLanguages)[number];
  messages: AbstractIntlMessages;
  timeZone: string;
}>) {
  const { useRouter: srcProxy, ...localizedNavigation } =
    createLocalizedPathnamesNavigation({
      locales: AvailableLanguages,
      localePrefix: 'always',
      pathnames,
    });

  const proxyRouter = useProxyRouter(srcProxy);

  return (
    <>
      <LanguageContext.Provider
        value={{
          pathnames,
          useRouter: proxyRouter,
          ...localizedNavigation,
        }}
      >
        <NextIntlClientProvider
          defaultTranslationValues={{
            important: (e) => <b className="font-bold">{e}</b>,
          }}
          timeZone={timeZone}
          locale={locale}
          messages={messages}
        >
          {children}
        </NextIntlClientProvider>
      </LanguageContext.Provider>
    </>
  );
}
