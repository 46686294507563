"use client";
import { Button } from "@/src/components/ui/button.tsx";
import { useLanguageContext } from "@/src/components/layout/ProvideLanguageContext.tsx";

export default function NotFound() {
  const { useRouter } = useLanguageContext();
  const router = useRouter();

  return (
    <div className="container mt-32 flex flex-col items-center space-y-4 text-center">
      <h1>page or translation not found</h1>
      <Button className="w-40" onClick={() => router.back()}>
        back
      </Button>
    </div>
  );
}
