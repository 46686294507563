import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { useEffect } from 'react';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isSSR() {
  return typeof window == 'undefined';
}

export function getImageSizes(
  desktopWidth: `${number}px`,
  mobileWidth = '100vw',
): string {
  return `(max-width: 1023px) ${mobileWidth}, ${desktopWidth}`;
}

function seededRandom(seed: number) {
  // Eine einfache Pseudozufallszahlengenerator-Funktion, die einen Seed verwendet
  return function () {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };
}

export function deterministicShuffle<T>(
  array: T[],
  seed: number | undefined = 100,
): T[] {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;
  const random = seededRandom(seed);

  // Während noch Elemente zu mischen übrig sind
  while (currentIndex !== 0) {
    // Wählen Sie ein verbleibendes Element...
    randomIndex = Math.floor(random() * currentIndex);
    currentIndex -= 1;

    // ... und tauschen Sie es mit dem aktuellen Element
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
