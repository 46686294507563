'use client';
import { sendGTMEvent } from '@next/third-parties/google';

type GtmRedirectToInstagramByUsername = {
  event: 'redirectToInstagramByUsername';
  username: string;
};
type GtmCtaButtonClick = {
  event: 'ctaButtonClick';
  buttonIdentifier: string;
  label?: string;
};
type GtmNavBurgerClick = {
  event: 'navBurgerClick';
};
type GtmNavLinkClick = {
  event: 'navLinkClick';
  link: string;
  label?: string;
};
type GtmOpenLayer = {
  event: 'openLayer';
  label: string;
};
export type GtmEvent =
  | GtmRedirectToInstagramByUsername
  | GtmCtaButtonClick
  | GtmNavBurgerClick
  | GtmNavLinkClick
  | GtmOpenLayer;

export function trackGtmEvent(event: GtmEvent) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(`[Tracking Event] ${event.event}`, event);
    return;
  }

  window.dispatchEvent(
    new CustomEvent('gtmEvent', { detail: Object.assign({}, event) }),
  );

  sendGTMEvent(event);
}
